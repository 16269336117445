@font-face {
  font-family: IRANSans;
  font-style : normal;
  font-weight: 400;
  src: url('./assets/fonts/eot/IRANSansWeb.eot');
  src: url('./assets/fonts/eot/IRANSansWeb.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/woff2/IRANSansWeb.woff2') format('woff2'), url('./assets/fonts/woff/IRANSansWeb.woff') format('woff'), url('./assets/fonts/ttf/IRANSansWeb.ttf') format('truetype')
}

@font-face {
  font-family: IRANSansBold;
  font-style : normal;
  font-weight: 800;
  src: url('./assets/fonts/eot/IRANSansWeb_Bold.eot');
  src: url('./assets/fonts/eot/IRANSansWeb_Bold.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/woff2/IRANSansWeb_Bold.woff2') format('woff2'), url('./assets/fonts/woff/IRANSansWeb_Bold.woff') format('woff'), url('./assets/fonts/ttf/IRANSansWeb_Bold.ttf') format('truetype')
}

body {
  margin: 0;
  font-family: IRANSans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: IRANSans;
}
